<template>
  <div>
    <!-- Carousel -->
    <Carousel :banners="banners" />
    <!-- Menu -->
    <Menu :wallet="wallet" class="mb-4 d-lg-none" />
    <!-- All games -->
    <div class="" style="overflow-x: auto; position: relative">
      <div class="d-flex" ref="games">
        <div class="symbol symbol-md-75 symbol-50 symbol-dark mr-3 flex-shrink-0 text-center game">
          <div class="symbol-label p-1" @click="selectGame('')" v-bind:class="{'bg-white': selectedGame === '' }">
            <img src="/images/allgame2.png" alt="" class="h-100 w-100" />
          </div>
          <div class="font-size-sm gamename">All Games</div>
        </div>
        <div v-for="game in games" v-bind:key="game.alias" class="symbol symbol-md-75 symbol-50 symbol-dark mr-3 flex-shrink-0 text-center game">
          <div class="symbol-label" @click="selectGame(game.alias)" v-bind:class="{'bg-white': selectedGame === game.alias }">
            <img :src="game.img" alt="" class="h-75" />
          </div>
          <div class="font-size-sm gamename">{{ game.alias }}</div>
        </div>
      </div>
    </div>
    <!-- Banner 300px -->
    <div v-if="headads" class="banner rounded">
      <div class="image300" :style="headadsstyle">
        <a v-if="headads.link" :href="headads.link" target="_blank"></a>
      </div>
    </div>
    <!-- All matches -->
    <div class="card card-custom gutter-b card-stretch mt-4">
      <div class="pt-0">
        <div class="d-flex">
          <div class="col-12 col-sm-8 p-2">คู่การแข่งขัน</div>
          <div class="d-none d-sm-block col-12 col-sm-4 p-2 text-info">เวลาปิดทายผล (Timezone: {{ timezone }})</div>
        </div>
        <template v-for="(m, idx) in matches">
          <div v-bind:key="`c${idx}`">
            <div class="row pt-2 pb-1 matchhead font-size-sm">
              <div class="col-8 col-sm-8 d-inline-block d-sm-flex px-0">
                <div class="col-12 col-sm-6 px-2 d-flex">
                  <div class="d-flex">
                    <div class="symbol symbol-20 symbol-dark mr-2 flex-shrink-0 text-center">
                      <div class="symbol-label">
                        <img v-if="m.tourimg" :src="m.tourimg" alt="" class="h-100 w-100" />
                      </div>
                    </div>
                  </div>
                  <div class="">{{ m.tourname }}</div>
                </div>
                <div class="col-12 col-sm-6 px-2">
                  <router-link :to="`/matches/${m.no}`" class="text-white">{{ m.name }}</router-link>
                </div>
              </div>
              <div class="col-3 col-sm-3 px-2 text-info">
                <span class="font-weight-bolder mr-2">{{ date(m) }}</span>
                <span>{{ time(m) }}</span>
              </div>
              <div class="col-1 col-sm-1 px-2 text-right">
                <span v-if="m.forfeit"><b-badge variant="danger" class="status">Forfeit</b-badge></span>
                <span v-else-if="m.ended"><b-badge variant="primary" class="status">Ended</b-badge></span>
                <span v-else-if="playing(m)"><b-badge variant="info" class="status">Playing</b-badge></span>
              </div>
            </div>
            <div class="col-12 col-xl-8 py-4 font-size-sm">
              <div class="card card-custom bg-dark p-1">
                <div v-for="(t, k) in m.choices" v-bind:key="`${idx}-${k}`" class="row align-items-center">
                  <div class="col-6 pl-4 p-1">
                    <b-row align-v="center" no-gutters>
                      <b-col cols="auto">
                        <div class="symbol symbol-20 symbol-dark mr-3 flex-shrink-0">
                          <div class="symbol-label">
                            <img v-if="t.teamimg" :src="t.teamimg" alt="" class="h-100 w-100" />
                          </div>
                        </div>
                      </b-col>
                      <b-col>
                        <strong>{{ t.teamname }}</strong>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="col-2 pl-3 pr-3">
                    {{ t.name }}
                  </div>
                  <div class="col-2 pl-3 pr-3">
                    <strong>{{ t.points }}</strong>
                  </div>
                  <div class="col-2 pl-3 pr-3">
                    <strong v-if="t.winpts" class="text-success">+{{ t.winpts }}</strong>
                    <strong v-else-if="m.forfeit" class="text-success">+{{ t.points }}</strong>
                    <strong v-else-if="m.ended" class="text-danger">-{{ t.points }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-if="loading" class="row mt-4">
          <div class="col-12 text-center">กำลังโหลด</div>
        </div>
        <div v-if="nomatch" class="row mt-4">
          <div class="col-12 text-center">ยังไม่ได้ทายผล</div>
        </div>
        <!-- Pagination -->
        <div v-if="count > 20" class="mt-6 d-flex justify-content-center">
          <b-pagination
            v-model="page"
            :total-rows="count"
            :per-page="20"
            @change="selectPage"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import Carousel from "@/view/content/Carousel.vue";
import Menu from './Menu'

export default {
  name: "dashboard",
  components: {
    Carousel,
    Menu
  },
  mounted() {
    this.recvData()
    this.recvMatches()
  },
  methods: {
    link(game) {
      return `/wallets/${this.wallet}/games/${game.alias}`
    },
    recvData() {
      this.axios.get(`/wallets/${this.wallet}/games`).then(
        ({ data }) => {
          this.info.no = data.no
          this.games = data.games
          this.banners = data.banners || []
          this.headads = data.headads || null
          this.pageads = data.pageads || {}
        }
      )
    },
    recvMatches() {
      this.loading = true
      var opt = {
        params: {}
      }

      if (this.selectedGame !== '') {
        opt.params.game = this.selectedGame
      }

      if (this.page > 1) {
        opt.params.page = this.page
      }

      this.axios.get(`/wallets/${this.wallet}/select`, opt).then(
        ({ data }) => {
          this.matches = data.matches
          this.count = data.count
        }
      ).finally(() => {
        this.loading = false
      })
    },
    date (m) {
      return dayjs(m.time).format('DD/MM/YYYY')
    },
    time (m) {
      return dayjs(m.time).format('HH:mm')
    },
    playing(m) {
      return dayjs() > dayjs(m.plan)
    },
    selectGame (game) {
      this.selectedGame = game
      this.page = 1
      this.recvMatches()
    },
    selectPage (page) {
      this.page = page
      this.recvMatches()
    },
    pageadshow (row) {
      return this.pageads != null && (this.pageads[`p${row}0`] != null || this.pageads[`p${row}1`] != null || this.pageads[`p${row}2`] != null)
    },
    pageadstyle (row, pos) {
      if (this.pageads != null && this.pageads[`p${row}${pos}`] != null) {
        return {
          'background-image': `url(${this.pageads[`p${row}${pos}`].img})`
        }
      }

      return {}
    }
  },
  computed: {
    wallet() {
      return this.$route.params.wallet
    },
    nomatches() {
      return this.matches.length === 0
    },
    timezone () {
      return dayjs().format('Z')
    },
    nomatch() {
      return this.loading === false && this.matches.length === 0
    },
    headadsstyle () {
      if (this.headads) {
        return {
          'background-image': `url(${this.headads.img})`
        }
      }

      return {}
    }
  },
  data() {
    return {
      info: {
        no: 0
      },
      games: [],
      matches: [],
      banners: [],
      headads: null,
      pageads: [],
      selectedGame: '',
      points: {},
      loading: true,
      count: 0,
      page: 1
    }
  }
};
</script>

<style lang="scss" scoped>
.game {
  cursor: pointer;
}

.gamename {
  width: 50px;
  font-size: 0.8rem;
}

@media (min-width: 768px) {
  .gamename {
    width: 75px;
    font-size: 0.925rem;
  }
}

.active {
  background-color: #4c4968;
}

.pool {
  background-color: #1d212a;
  border-color: #292f3c;
}

.pool.selected {
  border-color: #004500;
  background-color: #1f3511;
}

.pool:hover {
  cursor: pointer;
  border-color: #a9a9a9;
  background-color: #242530;
}

.matchhead {
  background-color: #3b445a;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
  border-top: 1px solid #14171D;

  .symbol-15 {
    width: 15px;
    height: 15px;
  }
}

.banner {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 10px;

  .image300 {
    // height: 300px;
    padding-top: 23.26%;
    // background-color: #3b445a;
    background-size: cover;
    background-position: top;
    position: relative;

    a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.banner2 {
  border-top: 1px solid #14171D;

  .image100 {
    height: 100px;
    // padding-top: 7.76%;
    // background-color: #3b445a;
    background-size: cover;
    background-position: center;
    position: relative;

    a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>